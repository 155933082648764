


















































































































import Vue from 'vue'
import moment from 'moment'
import store from '@/store';
// import request from '@/utils/request'
import axios from "axios";
import { Notify } from 'vant';
import storage from '@/store/storage'

const year = moment().year()

export default Vue.extend({
  data() {
    return {
        state: null,

        isShow: false,
        isShowTag: '',
        monthStart: new Date(year - 5, 0, 1),
        monthEnd: new Date(),

        comment: '暂无驳回理由',
        batchName: '',
        dateStart: null,
        dateEnd: null,
        datePick: null,
        summary: '',
        imagesLoader: [],
        imagesUrl: [],
        images: [],

        alertShow: false,
    }
  },
  async created() {
    // console.log(this.$route)
    // this.state = this.$route.params.state
    // if(this.state) {
    //     this.getData()
    // }
  },
  methods: {
    getData() {
        // console.log(11)
        // const id = this.$route.params.id
        // request.get(`/api/tea/rest_tracing_source/detail/${id}`).then(data => {
        //     console.log(data)
        //     const dateStartArr = [], dateEndArr = [], dateArr = []
        //     dateStartArr.push(String(data.teaStartTime).slice(0,4))
        //     dateStartArr.push(String(data.teaStartTime).slice(4,6))
        //     dateEndArr.push(String(data.teaEndTime).slice(0,4))
        //     dateEndArr.push(String(data.teaEndTime).slice(4,6))
        //     dateArr.push(String(data.pickTime).slice(0,4))
        //     dateArr.push(String(data.pickTime).slice(4,6))
        //     dateArr.push(String(data.pickTime).slice(6,8))
        //     data.dateStart = dateStartArr.join('-')
        //     data.dateEnd = dateEndArr.join('-')
        //     data.datePick = dateArr.join('-')
        //     const imagesUrl = []
        //     for (const i of data.images) {
        //         const imgUrl = `${request.BaseUrl}/tea_imgs${i.imgUrl}`
        //         imagesUrl.push(imgUrl)
        //     }
        //     data.imagesUrl = imagesUrl
        //     this.setData({obj: data})
        // })

    },
    getShow(val: any, tag: any) {
        this.isShow = val
        this.isShowTag = tag
    },
    confirm(e: any){
        const {isShowTag} = this
        if(isShowTag == 's') {
            this.dateStart = moment(e).format('YYYY - MM')
        } else if(isShowTag == 'e') {
            this.dateEnd = moment(e).format('YYYY - MM')
        }else if(isShowTag == 'd') {
            this.datePick = moment(e).format('YYYY - MM - DD')
        }
        this.isShow = false
    },
    change(e: any){
        const {isShowTag} = this
        if(isShowTag == 's') {
            this.dateStart = moment(e).format('YYYY - MM')
        } else if(isShowTag == 'e') {
            this.dateEnd = moment(e).format('YYYY - MM')
        }else if(isShowTag == 'd') {
            this.datePick = moment(e).format('YYYY - MM - DD')
        }
    },
    afterRead(file: any) {
        // console.log(file);
        file.status = 'uploading';
        file.message = '上传中...';
    
        const url = '/api/tobacco/file/upload'
        const params = {
            filePath: 'classroom',
            file: file.file,
        }
        const files = new FormData();
        for (const key in params) {
            files.append(key, params[key]);
        }
        axios({
            method: "post",
            headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            },
            url: `${url}`,
            data: files,
        }).then((res) => {
            // console.log(res.data)
            this.images.push(res.data)
            setTimeout(() => {
                file.status = 'done';
                file.message = '上传成功';
            }, 1000);
        }).catch((err) => {
            console.log(err)
            setTimeout(() => {
                file.status = 'failed';
                file.message = '上传失败';
            }, 1000);
        })
        // http://ylwx.sxktqx.com/files/classroom/20241115/fd0fe90bceee4a94b38f258bf45595e1.png
    },
    submitShow() {
        const { batchName, dateStart, dateEnd, datePick, summary, images } = this
        if (!batchName) return Notify({ type: 'warning', message: '茶叶批次不能为空' })
        if (!dateStart) return Notify({ type: 'warning', message: '开始时间不能为空' })
        if (!dateEnd) return Notify({ type: 'warning', message: '结束时间不能为空' })
        if (!datePick) return Notify({ type: 'warning', message: '采摘期不能为空' })
        // if (!summary) return Notify({ type: 'warning', message: '茶企总结不能为空' })
        // if (images.length == 0) return Notify({ type: 'warning', message: '茶园图片不能为空' })
        this.alertShow = true
    },
    submit(val: any) {
        if(val) {
            // const { batchName, dateStart, dateEnd, datePick, summary, images } = this
            // const params = {
            //     teaStartTime: Number(dateStart.split(' - ').join('')),
            //     teaEndTime: Number(dateEnd.split(' - ').join('')),
            //     pickTime: Number(datePick.split(' - ').join('')),
            //     batchName,
            //     summary,
            //     images,
            // }
            // console.log(params)
            if (this.state === 1) {
                // sourceId = this.data.id
                // const arr = params.images.map((e) => {
                //     if (typeof e == "object") {
                //     return e.imgUrl
                //     } else {
                //     return e
                //     }
                // })
                // params = {
                //     teaStartTime: Number(dateStart.split('-').join('')),
                //     teaEndTime: Number(dateEnd.split('-').join('')),
                //     pickTime: Number(datePick.split('-').join('')),
                //     batchName,
                //     summary,
                //     images: arr,
                //     sourceId,
                // }
                // console.log(params)
                // request.put('/api/tea/rest_tracing_source/modify', params).then(data => {
                //     // console.log(data)
                //     if (data) {
                //     getApp().globalData.loading = 'loading'
                //     wx.switchTab({  
                //         url: '/pages/attestation/attestation' 
                //     })
                //     }
                // }).catch(e => {
                //     wx.showToast({ title: '录入失败', icon: 'none', duration: 1000 })
                //     console.error(e)
                //     this.setData({alertShow: false})
                // })
            } else {
                // request.post('/api/tea/rest_tracing_source', params).then(data => {
                //     // console.log(data)
                //     if (data) {
                //         this.$router.push(`/source`)
                //     }
                //     this.alertShow = false
                // }).catch(e => {
                //     Notify({ type: 'danger', message: '录入失败：' + e })
                //     console.error(e)
                //     this.alertShow = false
                // })
                
                const newObj = {
                    batchName: this.batchName,
                    time: moment(new Date()).format('YYYY-MM-DD'),
                    id: moment(new Date()).format('DDHHmmss'),
                    examineStatus: 0,
                }
                const sourceItem = JSON.parse(storage.get('sourceItem'))
                if (!sourceItem) {
                    storage.set("sourceItem", JSON.stringify([newObj]));
                } else {
                    storage.set("sourceItem", JSON.stringify(sourceItem.concat(newObj)));
                }
                this.$router.push(`/source`)
                this.alertShow = false
            }
        } else {
            this.alertShow = false
        }
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
  },
})
